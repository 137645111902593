<template>
  <div>
    <div class="container">
      <div class="header">
        <div class="namebox">
          <img src="@/assets/logojpg.jpg" width="60px" height="60px" alt />
          <div style="color: darkorange;margin-left:10px">十</div>
          <div style="color: midnightblue;">万八</div>
        </div>

        <div style="font-size:14px;margin:10px">十万八是提供景点信息和自驾线路的小程序应用</div>

        <div style="margin: 10px;font-size:18px;margin-top:50px">请微信扫码开始使用</div>
      </div>
      <div class="qrbox">
        <div style="margin-right: 200px;">
          <img src="../assets/qrwechatmini.jpg" class="imgwrap" alt />
          <div>微信小程序</div>
        </div>
        <div>
          <img src="../assets/qrwechat.jpg" class="imgwrap" alt />
          <div>微信公众号</div>
        </div>
      </div>
      <!-- <div style="width: 50%;">
        <router-link to="/login">
          <div class="subhead">
            商家登录
            <i class="el-icon-right"></i>
          </div>
        </router-link>
      </div>-->
    </div>

    <div class="footer">
      <div>
        <span>新ICP备13003112号-2</span>
        <span style="margin:0 20px">增值电信许可: 新B2-20190299</span>
        <span>新公网安备 65010402000501</span>
      </div>
      <div>
        <a href="http://www.12377.cn/" target="blank">
          <img src="@/assets/report.png" height="14px" />
        </a>
      </div>
      <div>
        <span style="margin:0 20px;  cursor: pointer;" @click="open">隐私政策</span>
        <span>新疆启荣能源科技有限公司</span>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :direction="'rtl'"
      :with-header="false"
      title="ll"
      :modal="false"
      destroy-on-close
    >
      <div class="titlebox">隐私声明</div>
      <div style="padding: 0 30px 40px 30px;">
        <p>十万八非常重视对您的个人隐私保护，有时候我们需要某些信息才能为您提供您请求的服务，本隐私声明解释了这些情况下的数据收集和使用情况。</p>

        <p>本隐私声明适用于十万八的所有相关服务，随着十万八服务范围的扩大，隐私声明的内容可由十万八随时更新，且毋须另行通知。更新后的隐私声明一旦在网页上公布即有效代替原来的隐私声明。</p>

        <h4>我们收集哪些信息</h4>
        <p>通常，您能在匿名的状态下访问十万八并获取信息。当我们需要能识别您的个人信息或者可以与您联系的信息时，我们会征求您的同意。通常，在您注册十万八或申请开通新的功能时，我们可能收集这些信息：姓名，Email地址，住址和电话号码，并征求您的确认。</p>

        <h4>关于您的个人信息</h4>
        <p>十万八严格保护您个人信息的安全。我们使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄露。</p>
        <p>十万八会在法律要求或符合十万八的相关服务条款、软件许可使用协议约定的情况下透露您的个人信息，或者有充分理由相信必须这样做才能:</p>
        <p>(a) 满足法律或行政法规的明文规定，或者符合十万八网站适用的法律程序；</p>
        <p>(b）符合十万八相关服务条款、软件许可使用协议的约定；</p>
        <p>(c) 保护十万八的权利或财产，以及</p>
        <p>(d) 在紧急情况下保护十万八员工、产品或服务的用户或大众的个人安全。</p>
        <p>十万八不会未经您的允许将这些信息与第三方共享，本声明已经列出的上述情况除外。</p>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoSrc: require("../assets/logojpg.jpg"),
      drawer: false
    };
  },
  methods: {
    open() {
      this.drawer = true;
    }
  }
};
</script>

<style>
.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 160px;
  padding: 0 100px;
}

.header {
  padding: 0px 60px;
}
.namebox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 48px;
}

.subhead {
  font-size: 18px;
  box-sizing: border-box;
  cursor: pointer;
  color: #417cad;
}


a {
  text-decoration: none;
  color: initial;
}

.qrbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-left: 160px;
}

.imgwrap {
  width: 160px;
  height: 160px;
  box-sizing: border-box;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 12px;
  border-top: 1px solid darkorange;
  background: #fff;
}

.el-drawer__body {
  overflow: auto;
}

.titlebox {
  font-size: 18px;
  margin: 30px 0;
  text-align: center;
}

.imgboxwrap {
  margin: 0 40px;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.imgbox {
  width: 100px;
  height: 80px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid #eee;
}
.introbox {
  width: 80%;
  margin: 0px auto;
  padding: 10px 0;
  border-top: 1px dashed #ccc;
}

table tr :first-child {
  color: #777;
}

table td {
  padding: 5px 0;
  vertical-align: top;
}

.picbox {
  display: flex;
  justify-content: center;
  height: 400px;
}
.picstyle {
  height: 500px;
  border-radius: 10px;
  max-width: 80%;
}
.sighttitle {
  font-size: 18px;
}
.qrwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
}
.qrimgwrap {
  width: 80px;
  height: 80px;
  border: 1px solid #eee;
  margin-left: 20px;
}
.qrimgwrap img {
  width: 80px;
  height: 80px;
}
.listpricebox {
  color: #777;
  font-size: 12px;
  text-decoration: line-through;
}
</style>